import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/node';
import { CaptureContext, Event, Severity, User } from '@sentry/types';
import { Integration } from '@sentry/types/dist/integration';

import { getPublicConfiguration } from '@config/universal-config';
import { debug } from '@services/logging';

const { extractCurrentEnvironmentFull } = getPublicConfiguration();

export const sentryInit = () => {
    if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
        const integrations: Integration[] = [];
        if (
            process.env.NEXT_IS_SERVER === 'true' &&
            process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
        ) {
            // For Node.js, rewrite Error.stack to use relative paths, so that source
            // maps starting with ~/_next map to files in Error.stack with path
            // app:///_next
            integrations.push(
                new RewriteFrames({
                    iteratee: (frame: any) => {
                        frame.filename = frame.filename.replace(
                            process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
                            'app:///'
                        );
                        frame.filename = frame.filename.replace(
                            '.next',
                            '_next'
                        );
                        return frame;
                    }
                })
            );
        } else {
            // @ts-ignore
            window.Sentry = Sentry;
        }

        debug(`[support] found ${extractCurrentEnvironmentFull}`);
        Sentry.init({
            enabled: process.env.NODE_ENV === 'production',
            integrations,
            dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
            release: process.env.NEXT_PUBLIC_COMMIT_SHA,
            environment: extractCurrentEnvironmentFull
        });
    }
};

export function captureException(
    exception: any,
    captureContext?: CaptureContext
) {
    Sentry.captureException(exception, captureContext);
}

export function captureEvent(event: Event) {
    Sentry.captureEvent(event);
}

export function captureMessage(
    message: string,
    captureContext?: CaptureContext | Severity
) {
    Sentry.captureMessage(message, captureContext);
}

export interface ConferenceContext {
    conferenceId: string;
    conversationId?: string;
    joinCode?: string;
}

export function setConferenceContext(props: ConferenceContext) {
    Sentry.setContext('conference', props);
}

export function updateUserContext(props: User) {
    Sentry.setUser(props);
}
