import { extractFirst as extractFirstOriginal } from '@util/arg-utils';

/**
 * @Deprecated
 * @param src
 */
export function extractFirst(src?: string | string[]): string | null {
    return extractFirstOriginal(src);
}

export function createDefaultHeaders() {
    const headers = new Headers();
    headers.append('content-type', 'application/json');
    return headers;
}
