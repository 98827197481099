export interface SpeakerRoomConferenceMetadata {
    conferenceKey?: string;
    conferenceKeySyncError?: string;
    conferenceEventDate?: number;
    conferenceEventDateSyncError?: string;
    conferenceEventLink?: string;
    conferenceEventLinkSyncError?: string;
}

export const SpeakerRoomConferenceMetadataStub = {
    conferenceKey: undefined,
    conferenceKeySyncError: undefined,
    conferenceEventDate: undefined,
    conferenceEventDateSyncError: undefined,
    conferenceEventLink: undefined,
    conferenceEventLinkSyncError: undefined
};
