import { ConferenceEventInfo } from '@domain/conference-event-info';

import { getPublicConfiguration } from '@config/universal-config';
import { error } from '@services/logging';

import { createDefaultHeaders } from '@util/response-utils';

const publicConfiguration = getPublicConfiguration();
const defaultHeaders = createDefaultHeaders();
const API_ENDPOINT = `${publicConfiguration.backendEndpoint}/api/event`;

export function initConferenceEvent(request: {
    displayName?: string;
    ownerEmail?: string;
}): Promise<ConferenceEventInfo> {
    const { displayName, ownerEmail } = request;

    return fetch(`${API_ENDPOINT}`, {
        method: 'POST',
        headers: defaultHeaders,
        credentials: 'include',
        body: JSON.stringify({
            displayName,
            creator: {
                ownerEmail
            }
        })
    })
        .then((response) => response.json())
        .catch((reason) =>
            error(`Error during save conference info ${reason}`)
        );
}

export function updateConferenceEvent(
    conferenceId: string,
    fields: {
        displayName?: string;
        studioKey?: string;
        creator?: {
            ownerEmail?: string;
        };
    }
): Promise<ConferenceEventInfo | void> {
    if (!conferenceId) return Promise.resolve();
    return fetch(`${API_ENDPOINT}/${conferenceId}`, {
        method: 'PATCH',
        headers: defaultHeaders,
        credentials: 'include',
        body: JSON.stringify(fields)
    })
        .then<ConferenceEventInfo>((response) => response.json())
        .catch((reason) =>
            error(`Error during save conference info ${reason}`)
        );
}

export function updateConferenceEventUserSlot(
    conferenceId: string,
    options: {
        userId: string;
        endpointId?: string;
        slot?: number;
    }
): Promise<ConferenceEventInfo | void> {
    if (!conferenceId) return Promise.resolve();

    return fetch(`${API_ENDPOINT}/${conferenceId}/slot`, {
        method: 'PATCH',
        headers: defaultHeaders,
        credentials: 'include',
        body: JSON.stringify({
            ...options
        })
    })
        .then<ConferenceEventInfo>((response) => response.json())
        .catch((reason) =>
            error(`Error during save conference info ${reason}`)
        );
}

export function fetchConferenceEvent(
    eventId: string | undefined
): Promise<ConferenceEventInfo> {
    if (!eventId) {
        return Promise.reject('eventId required');
    }
    return fetch(`${API_ENDPOINT}/${eventId}`, { credentials: 'include' }).then(
        async (response) => {
            if (!response.ok) {
                let errorBody;
                try {
                    errorBody = await response.text();
                } catch (e) {
                    throw new Error(
                        `Can't load event info. Smt went wrong, http code ${response.status}`
                    );
                }
                throw new Error(`Can't load event info. ${errorBody}`);
            }
            return response.json();
        }
    );
    // .catch(reason => error(`Error when fetch conference info ${reason}`));
}

export function fetchAllConferenceEvents(): Promise<ConferenceEventInfo[]> {
    return fetch(`${API_ENDPOINT}s`, { credentials: 'include' })
        .then((response) => response.json())
        .then<any, ConferenceEventInfo[]>();
    // .catch(reason => error(`Error when fetch studios. ${reason}`, reason));
}

export function searchConferenceEvents(filters: {
    conferenceId?: string;
    displayName?: string;
    conferenceKey?: string;
    creator?: {
        ownerEmail?: string;
    };
    tsFrom?: number;
    tsTo?: number;
    byCreationTimestamp?: boolean;
    studioKey?: string;
}): Promise<{ results: ConferenceEventInfo[]; more: number }> {
    return fetch(`${API_ENDPOINT}/search`, {
        method: 'POST',
        headers: defaultHeaders,
        credentials: 'include',
        body: JSON.stringify(filters)
    }).then((response) => {
        if (response.status !== 200) {
            throw new Error("Can't search events, api is inaccessible");
        }
        return response.json();
    });
}

export function deleteConferenceEventById(
    conferenceId: string | undefined
): Promise<string | void> {
    if (!conferenceId) return Promise.resolve();

    return fetch(`${API_ENDPOINT}/${conferenceId}`, {
        method: 'DELETE',
        headers: defaultHeaders,
        credentials: 'include'
    }).then<void, string>((response) => {
        if (!response.ok) {
            const body: any = response.json();
            error(`Error during save conference info. ${body}`);
            return Promise.reject(body.message);
        }
    });
}

export function clearConferenceEventUsersById(
    conferenceId: string | undefined
): Promise<string | void> {
    if (!conferenceId) return Promise.resolve();

    return fetch(`${API_ENDPOINT}/${conferenceId}/users`, {
        method: 'DELETE',
        headers: defaultHeaders,
        credentials: 'include'
    }).then<void, string>((response) => {
        if (!response.ok) {
            const body: any = response.json();
            error(`Error during save conference info. ${body}`);
            return Promise.reject(body.message);
        }
    });
}

export function clearConferenceEventStudioKey(
    conferenceId: string
): Promise<void> {
    if (!conferenceId) return Promise.resolve();
    return fetch(`${API_ENDPOINT}/${conferenceId}/studioKey`, {
        method: 'DELETE',
        headers: defaultHeaders,
        credentials: 'include'
    }).then((response) => {
        if (response.ok) return;
        throw new Error(`Can't delete studio key for event: ${conferenceId}`);
    });
}
