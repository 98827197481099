import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import styles from './headings.module.scss';

type Props = {
    children?: ReactNode;
    className?: string;
    color?: 'default' | 'disabled' | 'accent' | 'white' | 'warning';
    noWrap?: boolean;
};
const Text: FC<Props> = ({
    children,
    className,
    color = 'default',
    noWrap = false
}) => {
    return (
        <span
            className={classNames(styles.text, styles[color], className, {
                [styles.noWrap]: noWrap
            })}
        >
            {children}
        </span>
    );
};

export default Text;
