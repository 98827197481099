import { makeAutoObservable, observable } from 'mobx';

import { computedFn } from 'mobx-utils';

import { debug } from '@services/logging';

import { VideoConferenceStore } from '@store/video-conference-store';

/**
 * Contains info with debug information which is available for each participant in the room.
 */
export class DebugStore {
    rootStore: VideoConferenceStore;

    debugEnabled: boolean = false;
    shortDebug: boolean = false;
    fullDebug: boolean = false;
    peerConnectionFlow: string[] = [];
    peerStatsByUserId = observable.map<string, any[]>();

    outboundRTPVideoStatsByUserID = observable.map<string, RTCStats[]>();
    outboundRTPAudioStatsByUserID = observable.map<string, RTCStats[]>();

    inboundRTPVideoStatsByUserID = observable.map<string, RTCStats[]>();
    inboundRTPAudioStatsByUserID = observable.map<string, RTCStats[]>();

    unhandledStatTypes = observable.set<string>();

    constructor(rootStore: VideoConferenceStore) {
        this.shortDebug = rootStore.isDebug;
        this.rootStore = rootStore;

        makeAutoObservable(this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // @actions ////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    toggleShortDebug() {
        this.shortDebug = !this.shortDebug;
    }

    toggleFullDebug() {
        this.fullDebug = !this.fullDebug;
    }

    enableDebug() {
        this.debugEnabled = true;
    }

    disableDebug() {
        this.debugEnabled = false;
    }

    addDebugMessage(info: string) {
        debug(info);
        this.peerConnectionFlow.push(info);
    }

    recordStat(userId: string, value: RTCStats[]) {
        const inboundRTPStats =
            this.inboundRTPVideoStatsByUserID.get(userId) ?? [];

        const newInboundRTPVideoStats =
            DebugStore.allInboundRTPVideoStat(value);
        const newInboundRTPAudioStats =
            DebugStore.allInboundRTPAudioStat(value);

        const mergedInboundVideoStats = inboundRTPStats.concat(
            newInboundRTPVideoStats
        );
        const mergedInboundAudioStats = inboundRTPStats.concat(
            newInboundRTPAudioStats
        );

        this.inboundRTPVideoStatsByUserID.set(userId, mergedInboundVideoStats);
        this.inboundRTPAudioStatsByUserID.set(userId, mergedInboundAudioStats);

        const outboundRTPStats =
            this.outboundRTPVideoStatsByUserID.get(userId) ?? [];

        const newOutboundRTPVideoStats =
            DebugStore.allOutboundRTPVideoStat(value);
        const newOutboundRTPAudioStats =
            DebugStore.allOutboundRTPAudioStat(value);

        const mergedOutboundVideoStats = outboundRTPStats.concat(
            newOutboundRTPVideoStats
        );
        const mergedOutboundAudioStats = outboundRTPStats.concat(
            newOutboundRTPAudioStats
        );

        this.outboundRTPVideoStatsByUserID.set(
            userId,
            mergedOutboundVideoStats
        );
        this.outboundRTPAudioStatsByUserID.set(
            userId,
            mergedOutboundAudioStats
        );

        value
            .filter(DebugStore.isNotInboundRTPStat)
            .filter(DebugStore.isNotOutboundRTPStat)
            .map((v) => v.type)
            .forEach((v) => this.recordUnhandledType(String(v)));
    }

    private static allInboundRTPAudioStat(value: RTCStats[]) {
        return value
            .filter(DebugStore.isInboundRTPStat)
            .filter(DebugStore.isAudioMediaType);
    }

    private static allInboundRTPVideoStat(value: RTCStats[]) {
        return value
            .filter(DebugStore.isInboundRTPStat)
            .filter(DebugStore.isVideoMediaType);
    }

    private static allOutboundRTPAudioStat(value: RTCStats[]) {
        return value
            .filter(DebugStore.isOutboundRTPStat)
            .filter(DebugStore.isAudioMediaType);
    }

    private static allOutboundRTPVideoStat(value: RTCStats[]) {
        return value
            .filter(DebugStore.isOutboundRTPStat)
            .filter(DebugStore.isVideoMediaType);
    }

    private static isVideoMediaType = (s: RTCStats) => {
        // if ('mediaType' in s) {
        //     return (s as RTCRTPStreamStats).mediaType === 'video';
        // }
        console.log(`s = ${s}`);
        return false;
    };

    private static isAudioMediaType = (s: RTCStats) => {
        // if ('mediaType' in s) {
        // return s.type === 'audio';
        // }
        console.log(`s = ${s}`);
        return false;
    };

    private static isInboundRTPStat = (s: RTCStats) =>
        s.type === 'inbound-rtp' ||
        // @ts-ignore
        s.type === 'inboundrtp';

    private static isNotInboundRTPStat = (s: RTCStats) =>
        !(
            s.type === 'inbound-rtp' ||
            // @ts-ignore
            s.type === 'inboundrtp'
        );

    private static isOutboundRTPStat = (s: RTCStats) =>
        s.type === 'outbound-rtp' ||
        // @ts-ignore
        s.type === 'outboundrtp';

    private static isNotOutboundRTPStat = (s: RTCStats) =>
        !(
            s.type === 'outbound-rtp' ||
            // @ts-ignore
            s.type === 'outboundrtp'
        );

    recordUnhandledType(type: string) {
        this.unhandledStatTypes.add(type);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // @computed ///////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    userStatsById = computedFn(
        (userId: string) => this.peerStatsByUserId.get(userId),
        { keepAlive: true, name: 'get user stats by id' }
    );

    userInboundVideoStats = computedFn(
        (userId: string) => this.inboundRTPVideoStatsByUserID.get(userId) ?? [],
        { keepAlive: true, name: 'get user inbound video stats by id' }
    );

    userInboundAudioStats = computedFn(
        (userId: string) => this.inboundRTPAudioStatsByUserID.get(userId) ?? [],
        { keepAlive: true, name: 'get user inbound audio stats by id' }
    );

    userOutboundVideoStats = computedFn(
        (userId: string) =>
            this.outboundRTPVideoStatsByUserID.get(userId) ?? [],
        { keepAlive: true, name: 'get user outbound video stats by id' }
    );

    userOutboundAudioStats = computedFn(
        (userId: string) =>
            this.outboundRTPAudioStatsByUserID.get(userId) ?? [],
        { keepAlive: true, name: 'get user outbound audio stats by id' }
    );
}
