import { makeAutoObservable } from 'mobx';

import { Session } from 'next-auth';
import { signIn, signOut } from 'next-auth/react';

import { debug } from '@services/logging';
import { WithSpeakerRoomUserFields } from '@sp-types/types';

import { SpeakerRoomRole } from '@online/speaker-room-sdk/security';
import { log } from '@online/speaker-room-sdk/utils/log';

export type SpeakerRoomAuthStatus =
    | 'authenticated'
    | 'unauthenticated'
    | 'loading';

export class RootStore {
    private session: WithSpeakerRoomUserFields<Session> | null;
    private status: SpeakerRoomAuthStatus;
    showAdvancedUserInfo: boolean;

    constructor(
        opts: { defaultStatus: SpeakerRoomAuthStatus } = {
            defaultStatus: 'loading'
        }
    ) {
        log.debug('[store/root] create new instance');
        this.session = {
            user: undefined,
            approved: false,
            expires: '1990-01-01',
            roles: ['anonymous']
        };
        this.status = opts.defaultStatus;
        this.showAdvancedUserInfo = false;

        makeAutoObservable(this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // @actions ////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    setShowAdvancedUserInfo(v: boolean): void {
        this.showAdvancedUserInfo = v;
    }

    setStatus(status: SpeakerRoomAuthStatus): void {
        this.status = status;
    }

    setSession(session: WithSpeakerRoomUserFields<Session> | null): void {
        this.session = session;
    }

    // TODO: type for providers
    signIn(provider = 'lk', callbackUrl?: string): Promise<void> {
        return signIn(provider, {
            callbackUrl
        }).then(() => {
            debug(`[UserStore] sign in`);
        });
    }

    signOut(callbackUrl?: string): Promise<void> {
        return signOut({ callbackUrl }).then(() => {
            debug(`[UserStore] sign out`);
        });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // @computed ///////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    get authenticationInFlight(): boolean {
        return this.status === 'loading';
    }

    get isOperatorUser(): boolean {
        return this.session?.roles?.includes('operator') ?? false;
    }

    get isCoordinatorUser(): boolean {
        return (
            (this.session?.roles?.includes('operator') ||
                this.session?.roles?.includes('coordinator')) ??
            false
        );
    }

    get isAdminUser(): boolean {
        return this.session?.roles?.includes('admin') ?? false;
    }

    get isAnonymousUser(): boolean {
        return (
            (this.session?.roles?.includes('anonymous') &&
                this.session?.roles?.length === 1) ??
            false
        );
    }

    get isAuthenticated(): boolean {
        return this.status === 'authenticated' && this.currentRoles.length > 1;
    }

    get isSpeakerRoomRole(): boolean {
        return this.isOperatorUser || this.isAdminUser;
    }

    get authenticatedUserId(): string | undefined {
        return this.session?.user?.id;
    }

    get authenticatedEmail(): string | undefined {
        return this.session?.user?.email ?? undefined;
    }

    get currentAuthenticationStatus(): SpeakerRoomAuthStatus {
        return this.status;
    }

    get currentRoles(): SpeakerRoomRole[] {
        return this.session?.roles ?? [];
    }

    get currentUserName(): string | undefined {
        return this.session?.user?.name ?? undefined;
    }
}
