import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

import {
    fetchConferenceEvent,
    initConferenceEvent,
    updateConferenceEvent
} from '@actions/api/conference-info';
import {
    CONFERENCE_INFO_STUB,
    ConferenceEventInfo
} from '@domain/conference-event-info';
import { StudioInfo } from '@domain/studio-info';
import { UserInfo } from '@domain/user-info';

import { PublicConfiguration } from '@config/config';
import { GlobalConfiguration } from '@config/global-configuration';
import { getPublicConfiguration } from '@config/universal-config';
import { LocalStorageService } from '@services/local-storage-service';
import {
    info,
    debug as originalDebug,
    error as originalError
} from '@services/logging';

import { DebugStore } from '@store/debug-store';
import {
    WithBaseInitialValue,
    WithReceiverInitialValue,
    WithSessionInitialValue
} from '@store/store-initial-values';
import { StudioAndConferenceStore } from '@store/studio-and-conference-store';
import { UserStore } from '@store/user-store';

import { WithPublicConfiguration } from '../types/conference-page-types';

enableStaticRendering(typeof window === 'undefined');

const debug = (msg: string) => {
    originalDebug(`[store/video-conference] ${msg}`);
};

const error = (msg: string, ...optionalParams: any[]) => {
    originalError(`[store/video-conference] ${msg}`, optionalParams);
};

export interface ConferenceStoreInitialValue
    extends WithPublicConfiguration,
        WithSessionInitialValue,
        WithBaseInitialValue,
        WithReceiverInitialValue {
    conferenceId: string | null;
    conferenceKey: string | null;
    conferenceInfo: ConferenceEventInfo | null;
    userInfo: UserInfo | null;
    studio: StudioInfo | null;
    //only for call now
    initConfiguration?: GlobalConfiguration | null;
}

export class VideoConferenceStore {
    userStore: UserStore;
    /**
     * @deprecated use useDevices() hook and useCall()
     */
    debugStore: DebugStore;
    publicConfiguration: PublicConfiguration;
    studioAndConferenceStore: StudioAndConferenceStore;

    conferenceInfo: Partial<ConferenceEventInfo> = {
        ...CONFERENCE_INFO_STUB
    };

    isDebug = false;
    isExperimental = false;
    globalErrorMessage: string | null;
    displayName?: string = undefined;

    storageService: LocalStorageService;

    constructor(initialValue: ConferenceStoreInitialValue | null) {
        debug('create new instance');
        this.storageService = new LocalStorageService({
            keysPrefix: 'root'
        });
        this.globalErrorMessage = null;
        if (initialValue) {
            this.isDebug = initialValue.isDebug;
            this.isExperimental = initialValue.isExperimental;
            this.publicConfiguration = initialValue.publicConfiguration;
            this.globalErrorMessage = initialValue.globalErrorMessage ?? null;
            if (initialValue.conferenceId) {
                this.conferenceInfo.conferenceId = initialValue.conferenceId;
            }
            if (initialValue.conferenceInfo?.conferenceId) {
                this.conferenceInfo.conferenceId =
                    initialValue.conferenceInfo.conferenceId;
            }
            if (initialValue.conferenceInfo?.conversationId) {
                this.conferenceInfo.conversationId =
                    initialValue.conferenceInfo?.conversationId;
            }
        }
        this.publicConfiguration = getPublicConfiguration();

        this.userStore = new UserStore(this, initialValue);
        this.debugStore = new DebugStore(this);
        this.studioAndConferenceStore = new StudioAndConferenceStore();

        makeAutoObservable(this, {
            storageService: false
        });

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // side effects ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        reaction(
            () => this.displayName,
            () => {
                if (!this.conferenceId || this.conferenceId === 'none') return;
                updateConferenceEvent(this.conferenceId!, {
                    displayName: this.displayName
                }).catch((e) =>
                    debug(`problem when syncing room. ${e.message}`)
                );
            },
            { name: '[store/video-conference] update name field' }
        );
    }

    hydrate(initialData: ConferenceStoreInitialValue) {
        if (initialData.conferenceInfo) {
            this.conferenceInfo = initialData.conferenceInfo;
        }
        this.publicConfiguration = initialData.publicConfiguration;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // actions /////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    setDebug(debug: boolean) {
        this.isDebug = debug;
    }

    async loadData(): Promise<ConferenceEventInfo | undefined> {
        if (this.conferenceId) {
            const conferenceInfo = await fetchConferenceEvent(
                this.conferenceId
            );
            debug(`Change conference name to ${conferenceInfo?.displayName}`);
            conferenceInfo?.displayName &&
                this.setDisplayName(conferenceInfo.displayName);
            runInAction(() => {
                if (conferenceInfo) {
                    this.conferenceInfo = conferenceInfo;
                }
            });
            return conferenceInfo;
        }
    }

    setConferenceId(conferenceId: string | null): void {
        if (this.conferenceInfo.conferenceId !== conferenceId) {
            this.conferenceInfo.conferenceId = conferenceId ?? undefined;
        }
    }

    resetGlobalErrorMessage(): void {
        this.globalErrorMessage = null;
    }

    setCurrentStudio(studio: StudioInfo): void {
        this.displayName = studio.activeConferenceInfo?.displayName;
    }

    setDisplayName(newvalue?: string): void {
        this.displayName = newvalue;
    }

    storeLocal(key: string, value: string): void {
        this.storageService.storeLocal(key, value);
    }

    findLocal(key: string): string | null {
        return this.storageService.findLocal(key);
    }

    get conferenceId(): string | undefined {
        return this.conferenceInfo.conferenceId;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Logical methods /////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /**
     * @return conferenceId or error
     */
    initializeConference(p: {
        ownerEmail: string | undefined | null;
    }): Promise<string | void> {
        return initConferenceEvent({
            displayName: this.displayName,
            ownerEmail: p.ownerEmail ?? undefined
        })
            .then((status) => {
                info(
                    `Save conference inf: status is ${JSON.stringify(status)}`
                );
                runInAction(() => this.setConferenceId(status.conferenceId));
                return status?.conferenceId;
            })
            .catch((reason) => {
                error(`Error while saving conference info. ${reason}`);
                throw new Error("Can't initialize new conference");
            });
    }
}
