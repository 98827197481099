import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';

export const speakerRoomTheme = deepMerge(grommet, {
    global: {
        font: {
            family: '"Montserrat", "Source Sans Pro", Helvetica, "Segoe UI", "Nimbus Sans No5 T CY", sans-serif'
        }
    },
    select: {
        control: {
            extend: {
                background: 'transparent',
                borderRadius: '3px',
                backdropFilter: 'blur(10px)'
                // background: 'rgba(0,0,0,0.3)',
                // textShadow: 'black 0 0 3px',
            }
        }
    }
});
