import { observable } from 'mobx';

import {
    SpeakerRoomConferenceMetadata,
    SpeakerRoomConferenceMetadataStub
} from '@domain/speaker-room-conference-metadata';

/**
 * Contains information about current room with participants.
 * conferenceId - used as id for room.
 * roomName - is obviously name of the room.
 * studioName - is number of the office studio.
 * machineName - is a number of PTS used to translate media in the office.
 * participants - all participants in current room.
 */
export interface ConferenceEventInfo {
    conferenceId: string;
    displayName: string;

    studioName?: string;
    machineName?: string;

    studioKey?: string;

    logUrl?: string;

    joinCode?: string;
    joinCodeUpdatedTimestamp?: number;

    conversationCreatorId?: string;
    conversationCreatorName?: string;
    conversationId?: string;

    metadata?: SpeakerRoomConferenceMetadata;
    creator?: {
        ownerEmail?: string;
        creationTimestamp?: number;
    };
}

export function isConferenceEventInfo(data: any): data is ConferenceEventInfo {
    if (!data) return false;
    if (typeof data === 'string') return false; // when fetch promise rejected it can contain string. Worse ..:)
    return 'conferenceId' in data;
}

export const CONFERENCE_INFO_STUB: ConferenceEventInfo = {
    conferenceId: 'none',
    displayName: 'none',

    studioName: undefined,
    machineName: undefined,

    studioKey: undefined,

    logUrl: undefined,

    joinCode: undefined,
    joinCodeUpdatedTimestamp: undefined,
    conversationId: undefined,
    metadata: observable({ ...SpeakerRoomConferenceMetadataStub }),
    creator: {
        ownerEmail: undefined,
        creationTimestamp: undefined
    }
};
