export const info = (msg: string, ...optionalParams: any[]) => {
    if (optionalParams?.length !== 0) {
        console.info(msg, optionalParams);
    } else {
        console.info(msg);
    }
};

export const error = (msg: any, ...optionalParams: any[]) => {
    if (optionalParams?.length !== 0) {
        console.error(msg, optionalParams);
    } else {
        console.error(msg);
    }
};

export const warn = (msg: string, ...optionalParams: any[]) => {
    if (optionalParams?.length !== 0) {
        console.warn(msg, optionalParams);
    } else {
        console.warn(msg);
    }
};

export const debug = (msg: any, ...optionalParams: any[]) => {
    if (optionalParams?.length !== 0) {
        console.debug(msg, optionalParams);
    } else {
        console.debug(msg);
    }
};

export const logGroup = (msg?: any) => {
    console.group(msg);
};

export const logGroupCollapsed = (msg?: any) => {
    console.groupCollapsed(msg);
};

export const logGroupEnd = () => {
    console.groupEnd();
};

export const debugTable = (msg: any) => {
    console.table(msg);
};
