import { FC, ReactNode } from 'react';

import { NotificationType } from '@components/layout/notifications-store';
import { Heading4 } from '@components/typography/heading4';

import styles from './card.module.scss';

export type Props = {
    type?: NotificationType;
    title?: string;
    footer?: string | JSX.Element;
    children?: ReactNode;
};

export const Card: FC<Props> = ({
    children,
    type = 'default',
    title,
    footer
}) => {
    return (
        <div className={`${styles.container} ${styles[type]}`}>
            <div className={styles.content}>
                {title && <Heading4 noMargin>{title}</Heading4>}
                {children}
            </div>
            {footer && <div className={styles.footer}>{footer}</div>}
        </div>
    );
};
