import { FC } from 'react';

import classNames from 'classnames';

import { HeadingProps } from '@components/typography/headings';

import styles from './headings.module.scss';

export const Heading4: FC<HeadingProps> = ({
    children,
    onClick,
    pointer,
    noMargin = false,
    noWrap = false,
    color = 'default',
    upper = false,
    lower = false,
    baseline = false,
    className
}) => {
    return (
        <h4
            onClick={onClick}
            className={classNames(styles.container, styles.h4, styles[color], {
                [styles.pointer]: pointer,
                [styles.noMargin]: noMargin,
                [styles.upper]: upper,
                [styles.lower]: lower,
                [styles.baseline]: baseline,
                [styles.noWrap]: noWrap,
                [className ?? '']: className
            })}
        >
            {children}
        </h4>
    );
};
