import { makeAutoObservable, observable, reaction, runInAction } from 'mobx';

import {
    approveExternalUser,
    disrankUser,
    fetchExternalUsers
} from '@actions/api/external-user';
import { ExternalUserInfo } from '@domain/user-info';

import { debug, error } from '@services/logging';

import {
    ConferenceStoreInitialValue,
    VideoConferenceStore
} from '@store/video-conference-store';

type IncomingMediaStates =
    | 'receive_only_studio'
    | 'receive_none'
    | 'receive_all'
    | 'receive_specific';

enum UserKeys {
    userDisplayName = 'userDisplayName'
}

export class UserStore {
    rootStore: VideoConferenceStore;

    currentUserId: string | null = null;
    _currentUserDisplayName?: string | null = null;

    terminated = false;
    terminatedReason?: string;

    incomingMediaState: IncomingMediaStates = 'receive_all';

    // external
    externalUsers: ExternalUserInfo[] = observable.array<ExternalUserInfo>([]);

    constructor(
        rootStore: VideoConferenceStore,
        initialInfo: null | ConferenceStoreInitialValue
    ) {
        this.rootStore = rootStore;

        initialInfo?.userInfo?.id &&
            this.setCurrentUserId(initialInfo.userInfo.id);
        initialInfo?.userInfo?.displayName &&
            this.setCurrentUserDisplayName(initialInfo.userInfo.displayName);
        makeAutoObservable(this);

        reaction(
            () => this.currentUserDisplayName,
            () => {
                if (this.currentUserDisplayName) {
                    this.rootStore.storeLocal(
                        UserKeys.userDisplayName,
                        this.currentUserDisplayName
                    );
                }
            },
            { name: '[UserStore] sync user display name with backend' }
        );
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // @computed ///////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // @action /////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    loadFromLocalStore(): void {
        if (!this.rootStore.storageService.isSupported()) return;

        const localName = this.rootStore.findLocal(UserKeys.userDisplayName);
        localName && this.setCurrentUserDisplayName(localName);
    }

    setCurrentUserId(userId: string): void {
        this.currentUserId = userId;
    }

    setCurrentUserDisplayName(name: string): void {
        this._currentUserDisplayName = name;
    }

    async fetchExternalUsers(): Promise<void> {
        const externalUsers = await fetchExternalUsers();

        runInAction(() => {
            this.externalUsers = observable.array(externalUsers);
        });
    }

    async approveUser(userId: string): Promise<void> {
        await approveExternalUser(userId)
            .then((approved) => {
                const user = this.externalUsers.find(
                    (value) => value.userId === userId
                );
                user && runInAction(() => (user.approved = true));
                debug(`[UserStore] approve user ${userId} result ${approved}`);
            })
            .catch((e) =>
                error(`[UserStore] Error approved for ${userId}: ${e}`)
            );
    }

    async disrankUser(userId: string): Promise<void> {
        await disrankUser(userId)
            .then((approved) => {
                const user = this.externalUsers.find(
                    (value) => value.userId === userId
                );
                user && runInAction(() => (user.approved = false));
                debug(`[UserStore] disrank user ${userId} result ${approved}`);
            })
            .catch((e) =>
                error(`[UserStore] Error disrank operation for ${userId}: ${e}`)
            );
    }

    get grouppedExternalUsers(): Map<boolean, ExternalUserInfo[]> {
        return this.externalUsers.reduce((acc, cur) => {
            if (!acc.has(cur.approved)) {
                acc.set(cur.approved, []);
            }

            acc.get(cur.approved)!.push(cur);

            return acc;
        }, observable.map<boolean, ExternalUserInfo[]>());
    }

    get currentUserDisplayName(): string | undefined {
        return this._currentUserDisplayName ?? undefined;
    }

    setIncomingMediaState(state: string): void {
        this.incomingMediaState = state as IncomingMediaStates;
    }
}
