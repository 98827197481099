import { error } from '@services/logging';

import { strToBoolean } from '@util/convert';

const isSeverSide = typeof window === 'undefined';
const localStorageIsAvailable = !isSeverSide && 'localStorage' in window;

export class LocalStorageService {
    private readonly globalKeysPrefix: string = 'speaker-room-data';
    private readonly storeKeysPrefix: string = 'common';

    constructor(options: { globalKeysPrefix?: string; keysPrefix?: string }) {
        if (options.globalKeysPrefix) {
            this.globalKeysPrefix = options.globalKeysPrefix;
        }
        if (options.keysPrefix) {
            this.storeKeysPrefix = options.keysPrefix;
        }
    }

    storeLocal(key: string, value: string): void {
        if (localStorageIsAvailable) {
            localStorage.setItem(
                `${this.globalKeysPrefix}-${this.storeKeysPrefix}-${key}`,
                value
            );
        } else {
            !isSeverSide &&
                error(
                    '[LocalStorageService] local storage does not support. Please use compatible browser'
                );
        }
    }

    findLocal(key: string): string | null {
        if (localStorageIsAvailable) {
            return localStorage.getItem(
                `${this.globalKeysPrefix}-${this.storeKeysPrefix}-${key}`
            );
        } else {
            !isSeverSide &&
                error(
                    '[LocalStorageService] local storage does not support. Please use compatible browser'
                );
            return null;
        }
    }

    findLocalBoolean(key?: string): boolean {
        if (!key) return false;
        const result = this.findLocal(key);
        if (!result) return false;
        return strToBoolean(result);
    }

    isSupported(): boolean {
        return localStorageIsAvailable;
    }
}
