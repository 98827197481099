import { FC } from 'react';

import classNames from 'classnames';

import styles from './spinner.module.scss';

interface Props {
    color?: 'white' | 'warning' | 'accent';
    size?: 'xsmall' | 'small' | 'medium' | 'large';
}

export const Spinner: FC<Props> = ({ color = 'accent', size = 'medium' }) => {
    return (
        <div
            className={classNames(
                styles.hourglass,
                styles[color],
                styles[size]
            )}
        />
    );
};
