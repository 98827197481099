import { FC, createContext, useContext } from 'react';

import { enableStaticRendering } from 'mobx-react-lite';

import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';

import { WithSpeakerRoomUserFields } from '@sp-types/types';

import { log } from '@online/speaker-room-sdk/utils/log';
import { useReactiveEffect } from '@online/speaker-room-sdk/utils/mobx';

import { RootStore } from '@store/root-store';
import { WithSessionInitialValue } from '@store/store-initial-values';
import { useGentleVideoConferenceStore } from '@store/video-conference-session-helpers';

enableStaticRendering(typeof window === 'undefined');

const Context = createContext<RootStore | undefined>(undefined);

export function withRootStore(Component: FC<any>) {
    const store = new RootStore();

    const element: FC = function (props: WithSessionInitialValue) {
        if (typeof window !== 'undefined') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.rootStore = store;
        }

        return (
            <Context.Provider value={store}>
                <Component {...props} />
            </Context.Provider>
        );
    };
    element.displayName = 'withRootStore';
    return element;
}

export function useRootStore(): RootStore {
    const store = useContext(Context);
    const { data: session, status } = useSession();
    const videoConferenceStore = useGentleVideoConferenceStore();

    if (!store) {
        throw new Error(
            'Use useRootStore must be used only within withRootStore'
        );
    }

    useReactiveEffect(() => {
        store.setStatus(status);
        store.setSession(session as WithSpeakerRoomUserFields<Session>);

        if (videoConferenceStore) {
            videoConferenceStore.userStore.loadFromLocalStore();
            if (
                session?.user?.name &&
                videoConferenceStore.userStore.currentUserDisplayName ===
                    undefined
            ) {
                log.debug(
                    `[use-root-store] update user display name from session to ${session.user.name}`
                );
                videoConferenceStore.userStore.setCurrentUserDisplayName(
                    session.user.name
                );
            }
        }
    }, [session, status]);

    return store;
}
