import React, { FC, useContext } from 'react';

import { enableStaticRendering } from 'mobx-react-lite';

import { createStrictContext } from '@online/speaker-room-sdk/store/create-strict-context';

import { VideoConferenceStore } from './video-conference-store';

enableStaticRendering(typeof window === 'undefined');

const [
    VideoConferenceContextProvider,
    useVideoConferenceStore,
    VideoConferenceContext
] = createStrictContext<VideoConferenceStore>({
    name: 'VideoConferenceStore',
    errorMessage:
        'Decorate withVideoConferenceSessionStore before call useVideoConferenceStore function'
});

let store: VideoConferenceStore | null = null;
export function withVideoConferenceSessionStore<T = any>(
    Component: FC<T>
): React.FC<T> {
    const element: FC<any> = function (props: any) {
        const initialState = props ?? null;
        if (!store) {
            store = new VideoConferenceStore(initialState);
        }

        if (initialState) {
            store.hydrate(initialState);
        }

        if (typeof window !== 'undefined') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.store = store;
        }

        return (
            <VideoConferenceContextProvider value={store}>
                <Component {...props} />
            </VideoConferenceContextProvider>
        );
    };
    element.displayName = 'withVideoConferenceSessionStore';
    return element;
}

export function useGentleVideoConferenceStore():
    | VideoConferenceStore
    | undefined {
    return useContext(VideoConferenceContext);
}

export { useVideoConferenceStore, VideoConferenceContext };
