import { StrictMode, useEffect } from 'react';

import {
    configure,
    getDependencyTree,
    isObservable,
    isObservableProp
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

import { AppProps } from 'next/app';
import Head from 'next/head';
import { Router } from 'next/router';

import { updateClipboard } from '@actions/browser/copy-to-clipboard';
import { speakerRoomTheme } from '@styles/styles';
import { Grommet } from 'grommet';
import { SessionProvider } from 'next-auth/react';

import { getPublicConfiguration } from '@config/universal-config';
import { debug } from '@services/logging';

import { isClientSide } from '@online/speaker-room-sdk/media';

import {
    sentryInit,
    setConferenceContext,
    updateUserContext
} from '@util/sentry';
import { getOrInitTabSession } from '@util/session-id';

import { withRootStore } from '@store/root-store-helpers';
import { ConferenceStoreInitialValue } from '@store/video-conference-store';

import { Notifications } from '@components/layout/notifications';
import { withNotifications } from '@components/layout/notifications-context';

import '@styles/styles.scss';

enableStaticRendering(!isClientSide());

const { isProduction, basePath } = getPublicConfiguration();

configure({
    enforceActions: 'always',
    computedRequiresReaction: true,
    reactionRequiresObservable: true,
    observableRequiresReaction: true,
    disableErrorBoundaries: !isProduction
});

sentryInit();

if (!isProduction) {
    if (typeof window !== 'undefined') {
        debug(`[_app] enable debug logging for mobx`);
        //TODO add mox debug logging
    }
}

getOrInitTabSession();

Router.events.on('routeChangeStart', () => {
    if ('notificationsStore' in window) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.notificationsStore.showLoadingWithText('Waiting...');
    }
});
Router.events.on('routeChangeComplete', () => {
    if ('notificationsStore' in window) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.notificationsStore.hideLoading();
    }
});
Router.events.on('routeChangeError', (...allargs) => {
    if ('notificationsStore' in window) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.notificationsStore.hideLoading();
    }
    console.error('routeChangeError', allargs);
});

//TODO For debug purposes. Maybe remove from production code
if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.copy = updateClipboard;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.iso = isObservable;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.isop = isObservableProp;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.gdt = getDependencyTree;
}

const App = ({
    Component,
    pageProps
}: AppProps<ConferenceStoreInitialValue>) => {
    const { userInfo, theme } = pageProps;

    useEffect(() => {
        pageProps.conferenceId &&
            setConferenceContext({
                conferenceId: pageProps.conferenceId
            });
        updateUserContext({
            id: userInfo?.id,
            username: userInfo?.displayName ?? undefined
        });
    }, [userInfo, userInfo?.id, userInfo?.displayName]);

    return (
        <StrictMode>
            <SessionProvider basePath={`${basePath}/api/auth`}>
                <Grommet
                    plain
                    theme={speakerRoomTheme}
                    themeMode={theme ? theme : 'light'}
                >
                    <Head>
                        <title>
                            SpeakerRoom - Video calls for advanced conferencing
                        </title>
                        <meta
                            name="viewport"
                            content="initial-scale=1.0, width=device-width"
                        />
                        <link rel="icon" href={`${basePath}/favicon.ico`} />
                    </Head>
                    <Component {...pageProps} />{' '}
                    {/* FIXME: use common layout for pages that need it */}
                    <Notifications />
                </Grommet>
            </SessionProvider>
        </StrictMode>
    );
};

export default withRootStore(withNotifications(App));
