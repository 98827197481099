import { FC, createContext } from 'react';

import { NotificationsStore } from '@components/layout/notifications-store';

export const NotificationsContext = createContext<
    NotificationsStore | undefined
>(undefined);

export function withNotifications(Component: FC<any>) {
    const notificationsStore = new NotificationsStore();

    const element: FC = function (props: any) {
        //TODO For debug purposes. Maybe remove from production code
        if (typeof window !== 'undefined') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.notificationsStore = notificationsStore;
        }

        return (
            <NotificationsContext.Provider value={notificationsStore}>
                <Component {...props} />
            </NotificationsContext.Provider>
        );
    };

    element.displayName = 'withNotificationStore';

    return element;
}
