import { StudioInfo } from '@domain/studio-info';

import { getPublicConfiguration } from '@config/universal-config';
import { error } from '@services/logging';

import { createDefaultHeaders } from '@util/response-utils';

const publicConfiguration = getPublicConfiguration();
const defaultHeaders = createDefaultHeaders();
const API_ENDPOINT = `${publicConfiguration.basePath}/api/studio`;

export function updateStudioActiveConferenceId(
    studio: StudioInfo
): Promise<{ status: string }> {
    return fetch(`${API_ENDPOINT}/active-conference-id`, {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify(studio),
        credentials: 'include'
    }).then(async (response) => {
        if (!response.ok) {
            const jsonWithError = await response.json();
            error(
                `${
                    jsonWithError.message ??
                    'Sth went wrong without any description, we are sorry..'
                }`
            );

            return { status: jsonWithError.status };
        }
        return { status: 'ok' };
    });
}

export function updateStudio(studio: StudioInfo): Promise<StudioInfo | void> {
    return fetch(`${API_ENDPOINT}`, {
        method: 'PUT',
        headers: defaultHeaders,
        body: JSON.stringify(studio)
    }).then(async (response) => {
        if (!response.ok) {
            const jsonWithError = await response.json();

            throw new Error(
                `${
                    jsonWithError.message ??
                    'Sth went wrong without any description, we are sorry..'
                }`
            );
        }
        return response.json();
    });
}

export function insertStudio(studio: StudioInfo): Promise<StudioInfo | void> {
    return fetch(`${API_ENDPOINT}`, {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify(studio)
    }).then(async (response) => {
        if (!response.ok) {
            const jsonWithError = await response.json();

            throw new Error(
                `${
                    jsonWithError.message ??
                    'Sth went wrong without any description, we are sorry..'
                }`
            );
        }
        return response.json();
    });
}

export function fetchStudios(): Promise<StudioInfo[]> {
    return fetch(`${API_ENDPOINT}`)
        .then(async (response) => {
            if (!response.ok) {
                const jsonWithError = await response.json();

                throw new Error(
                    `${
                        jsonWithError.message ??
                        'Sth went wrong without any description, we are sorry..'
                    }`
                );
            }
            return response.json();
        })
        .then<any, StudioInfo[]>()
        .catch((reason) =>
            error(`Error when fetch studios. ${reason}`, reason)
        );
}

export function fetchStudioByLocation(
    studioName?: string,
    machineName?: string
): Promise<StudioInfo> {
    return fetch(`${API_ENDPOINT}/by-location`, {
        method: 'POST',
        body: JSON.stringify({
            machineName,
            studioName
        }),
        headers: defaultHeaders
    })
        .then(async (response) => {
            if (!response.ok) {
                const jsonWithError = await response.json();

                throw new Error(
                    `${
                        jsonWithError.message ??
                        'Sth went wrong without any description, we are sorry..'
                    }`
                );
            }
            return response.json();
        })
        .then<any, StudioInfo[]>()
        .catch((reason) => {
            error(`Error when fetch studios. ${reason}`, reason);
            throw reason;
        });
}

export function deleteStudio(studio: StudioInfo): Promise<void> {
    const encodedStudio = encodeURIComponent(JSON.stringify(studio));
    return fetch(`${API_ENDPOINT}?studio=${encodedStudio}`, {
        method: 'DELETE',
        headers: defaultHeaders,
        body: JSON.stringify(studio)
    }).then(async (response) => {
        if (!response.ok) {
            const jsonWithError = await response.json();
            throw new Error(
                `${
                    jsonWithError.message ??
                    'Sth went wrong when delete studio. No any description, we are sorry..'
                }`
            );
        }
    });
}
