export function strToBoolean(str: string): boolean {
    return str === 'true';
}

export function convertJiraLinkToDisplayNameOrDefault(link?: string) {
    if (!link) return '';
    if (link.startsWith('https://jira')) {
        const parts = link.split('/');
        if (parts.length < 4) return link;
        return parts[parts.length - 1];
    }
    return link;
}

export function stringifyUnknown(data: any): string {
    if (!data) return '';
    if (typeof data === 'string') return data;
    if ('toString' in data) {
        return data.toString();
    }

    return 'Found something unknown';
}
