import { ExternalUserInfo } from '@domain/user-info';

import { getPublicConfiguration } from '@config/universal-config';
import { error } from '@services/logging';

import { createDefaultHeaders } from '@util/response-utils';

const publicConfiguration = getPublicConfiguration();
const API_ENDPOINT = `${publicConfiguration.basePath}/api/user/external`;
const defaultHeaders = createDefaultHeaders();

export async function fetchExternalUsers(): Promise<ExternalUserInfo[]> {
    const url = API_ENDPOINT;
    try {
        const resp = await fetch(url, { credentials: 'include' });
        const users: ExternalUserInfo[] = await resp.json();

        return users;
    } catch (reason) {
        error(`Error during fetch external users ${reason}`);
        return [];
    }
}

export async function approveExternalUser(userId: string): Promise<boolean> {
    const url = `${API_ENDPOINT}/approve`;

    try {
        const resp = await fetch(url, {
            method: 'PUT',
            headers: defaultHeaders,
            body: JSON.stringify({ userId })
        });
        return resp.ok;
    } catch (reason) {
        error(`Error during aprove external users ${reason}`);
        return false;
    }
}

export async function disrankUser(userId: string): Promise<boolean> {
    const url = `${API_ENDPOINT}`;

    try {
        const resp = await fetch(url, {
            method: 'DELETE',
            headers: defaultHeaders,
            body: JSON.stringify({ userId })
        });
        return resp.ok;
    } catch (reason) {
        error(`Error during disrank external users ${reason}`);
        return false;
    }
}
