import { FC } from 'react';

import { observer } from 'mobx-react-lite';

import { Layer } from 'grommet';

import { Spinner } from '@components/helpers/spinner';
import { Box } from '@components/layout/box';
import { Card } from '@components/layout/card';
import { useNotifications } from '@components/layout/notifications-store';
import Text from '@components/typography/text';

import styles from './notifications.module.scss';

type Props = {
    position?:
        | 'bottom'
        | 'bottom-left'
        | 'bottom-right'
        | 'center'
        | 'hidden'
        | 'left'
        | 'right'
        | 'top'
        | 'top-left'
        | 'top-right';
};

export const Notifications: FC<Props> = observer(
    ({ position = 'top-right' }) => {
        const notificationStore = useNotifications();
        const {
            notifications,
            isLoadingVisible,
            loadingText,
            renderableComponent
        } = notificationStore;

        if (notifications.length === 0 && !isLoadingVisible) return <></>;

        return (
            <>
                {isLoadingVisible && (
                    <Layer
                        full
                        position="center"
                        className={styles.loadingContainer}
                    >
                        <Box fill align="center" justify="center">
                            {!renderableComponent && <Spinner color="white" />}
                            {!renderableComponent && (
                                <Text className={styles.loadingText}>
                                    {loadingText}
                                </Text>
                            )}
                            {renderableComponent && renderableComponent()}
                        </Box>
                    </Layer>
                )}
                <Layer
                    position={position}
                    modal={false}
                    margin={{ vertical: 'medium', horizontal: 'small' }}
                    responsive={false}
                    plain
                >
                    <div className={styles.container}>
                        {notifications.map((notification, index) => (
                            <div
                                key={index}
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    notificationStore.removeNotification(
                                        notification
                                    )
                                }
                            >
                                <Card
                                    type={notification.type}
                                    title={notification.title}
                                    footer={notification.footerText}
                                >
                                    {notification.textRenderer ? (
                                        notification.textRenderer()
                                    ) : (
                                        <Text>{notification.text}</Text>
                                    )}
                                </Card>
                            </div>
                        ))}
                    </div>
                </Layer>
            </>
        );
    }
);
