import { useContext } from 'react';

import { makeAutoObservable, observable, reaction, runInAction } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

import { debug } from '@services/logging';

import { NotificationsContext } from '@components/layout/notifications-context';

enableStaticRendering(typeof window === 'undefined');

export enum NotificationType {
    default = 'default',
    warning = 'warning',
    success = 'success',
    critical = 'critical'
}

export interface NotificationDescription {
    title?: string;
    text?: string;
    textRenderer?: () => JSX.Element;
    footerText?: string;
    icon?: 'network' | 'latency'; //TODO implement this
    type?: NotificationType;
    disappearAfterMs?: number;
}

export class NotificationsStore {
    notifications = observable.array<NotificationDescription>([]);
    private _ignoreForMs = 0;
    private _ignoreForMsTimeout?: any = undefined;
    private _loadingIsVisible: boolean = false;
    private _loadingText?: string = undefined;
    private _currentComponent?: () => JSX.Element = undefined;

    constructor() {
        makeAutoObservable(this, {
            handleNotificationAddEvent: false
        } as any);
        reaction(
            () => this._ignoreForMs,
            (ignoreForMs) => {
                clearTimeout(this._ignoreForMsTimeout);
                if (ignoreForMs !== Number.POSITIVE_INFINITY) {
                    this._ignoreForMsTimeout = setTimeout(
                        () => this.disableIgnoring(),
                        ignoreForMs
                    );
                }
            },
            { name: '[NotificationsStore] Handle ignore notifications timeout' }
        );
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // action //////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    success(description: NotificationDescription) {
        description.type = NotificationType.success;
        this.handleNotificationAddEvent(description);
    }

    warning(description: NotificationDescription) {
        description.type = NotificationType.warning;
        this.handleNotificationAddEvent(description);
    }

    common(description: NotificationDescription) {
        description.type = NotificationType.default;
        this.handleNotificationAddEvent(description);
    }

    critical(description: NotificationDescription) {
        description.type = NotificationType.critical;
        this.handleNotificationAddEvent(description);
    }

    ignoreNotificationsForMs(ignoreForMs: number) {
        this._ignoreForMs = ignoreForMs;
    }

    disableIgnoring() {
        this._ignoreForMs = 0;
        clearTimeout(this._ignoreForMsTimeout);
    }

    showLoadingWithComponent(component: () => JSX.Element) {
        this._currentComponent = component;
        this.showLoading();
    }

    showLoadingWithText(text: string) {
        this._loadingText = text;
        this.showLoading();
    }

    showLoading() {
        this._loadingIsVisible = true;
    }

    hideLoading() {
        this._loadingIsVisible = false;
        this._loadingText = undefined;
        this._currentComponent = undefined;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // computed ////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    get renderableComponent() {
        return this._currentComponent;
    }

    get loadingText() {
        return this._loadingText;
    }

    get isLoadingVisible() {
        return this._loadingIsVisible;
    }

    get isIgnoreNotifications() {
        return this._ignoreForMs !== 0;
    }

    get currentIgnoreTimeoutThreshold() {
        return this._ignoreForMs;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // not mobx/////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    private handleNotificationAddEvent(description: NotificationDescription) {
        if (this.isIgnoreNotifications) {
            debug(
                `[NotificationStore] ignore notificatino: ${JSON.stringify(
                    description
                )}`
            );
            return;
        }

        const currentLength = this.notifications.push(description);
        const currentElement = this.notifications[currentLength - 1];
        setTimeout(
            () => runInAction(() => this.notifications.remove(currentElement)),
            description.disappearAfterMs ?? 1000
        );
    }

    removeNotification(notification: NotificationDescription) {
        this.notifications.remove(notification);
    }
}

export function useNotifications() {
    const store = useContext(NotificationsContext);

    if (!store)
        throw new Error(
            'Use useNotifications must be used only within withNotifications'
        );

    return store;
}
