import { makeAutoObservable, observable, reaction, runInAction } from 'mobx';

import {
    deleteConferenceEventById,
    fetchAllConferenceEvents,
    fetchConferenceEvent
} from '@actions/api/conference-info';
import {
    deleteStudio,
    fetchStudioByLocation,
    fetchStudios,
    insertStudio,
    updateStudio
} from '@actions/api/studio-info';
import {
    CONFERENCE_INFO_STUB,
    ConferenceEventInfo,
    isConferenceEventInfo
} from '@domain/conference-event-info';
import { StudioInfo } from '@domain/studio-info';
import { IPromiseBasedObservable, computedFn, fromPromise } from 'mobx-utils';

import { debug } from '@services/logging';

export class StudioAndConferenceStore {
    studios = observable.array<StudioInfo>([]);
    studioSyncError?: string;
    problemOnDeleteConference?: string;
    fetchAllConferencesPromise?: IPromiseBasedObservable<ConferenceEventInfo[]>;
    fetchStudiosPromise?: IPromiseBasedObservable<void>;
    private loadingCallInfo = observable.map<
        string,
        IPromiseBasedObservable<ConferenceEventInfo>
    >();
    private loadingStudioInfoPromise?: IPromiseBasedObservable<StudioInfo>;

    constructor() {
        makeAutoObservable(this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // @action /////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /**
     * Find studio by @param studioName and @param machineName
     * @param options.studioName
     * @param options.machineName
     */
    async loadStudio(options: {
        studioName?: string;
        machineName?: string;
    }): Promise<StudioInfo> {
        const studioInfoPromise = fetchStudioByLocation(
            options.studioName,
            options.machineName
        );
        this.loadingStudioInfoPromise = fromPromise(studioInfoPromise);
        return studioInfoPromise;
    }

    loadConferenceEvent(callId: string) {
        const fetchCallId = fromPromise(fetchConferenceEvent(callId));
        this.loadingCallInfo.set(callId, fetchCallId);
    }

    loadStudios() {
        const fetchPromise = fetchStudios().then((studiosFromResponse) => {
            runInAction(() => {
                this.studios = observable.array(studiosFromResponse);
                this.studios?.forEach((studio) => {
                    reaction(
                        () => studio.activeConferenceId,
                        () => {
                            updateStudio(studio)
                                .then(() =>
                                    debug(
                                        `[StudioAndConferenceStore] studio ${studio.studioName}:${studio.machineName} updated`
                                    )
                                )
                                .catch((e) => {
                                    runInAction(() => {
                                        studio.activeConferenceId = undefined;
                                        this.studioSyncError = `Can't sync ${studio.studioName}:${studio.machineName}. ${e.message}`;
                                    });
                                });
                        },
                        {
                            name: `[StudioAndConferenceStore] sync activeConferenceId for ${studio.studioName}:${studio.machineName}`
                        }
                    );
                });
            });
        });
        this.fetchStudiosPromise = fromPromise(fetchPromise);
        return fetchPromise;
    }

    loadConferences() {
        const fetchPromise = fetchAllConferenceEvents();
        this.fetchAllConferencesPromise = fromPromise(
            fetchPromise,
            this.fetchAllConferencesPromise
        );
        return fetchPromise;
    }

    deleteConference(conferenceId: string) {
        deleteConferenceEventById(conferenceId).catch((reason) => {
            runInAction(() => (this.problemOnDeleteConference = reason));
        });
    }

    clearStudioSyncError() {
        this.studioSyncError = undefined;
    }

    addStudio(studio: StudioInfo) {
        if (!studio.activeConferenceId) {
            studio.activeConferenceId = undefined;
        }
        return insertStudio(studio).then(() => {
            this.studios.push(studio);
        });
    }

    deleteStudio(studio: StudioInfo) {
        deleteStudio(studio).then(() => {
            runInAction(() => {
                const index = this.studios.findIndex(
                    (s) =>
                        s.machineName === studio.machineName &&
                        s.studioName !== studio.studioName
                );
                this.studios.splice(index, 1);
            });
        });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // @computed ///////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    get targetStudioInfoPromise() {
        return this.loadingStudioInfoPromise;
    }

    get targetStudioInfo() {
        return this.loadingStudioInfoPromise?.value;
    }

    get sortedStudios() {
        return this.studios
            .slice()
            .sort((a, b) =>
                (a.studioName + a.machineName).localeCompare(
                    b.studioName + b.machineName
                )
            );
    }

    get groupedByStudioNameStudios() {
        return this.sortedStudios.reduce((map, next) => {
            let newVar = map.get(next.studioName);
            if (!newVar) {
                map.set(next.studioName, []);
            }
            map.get(next.studioName)!.push(next);
            return map;
        }, observable.map<string, StudioInfo[]>());
    }

    get allConferences(): ConferenceEventInfo[] {
        return this.conferences;
    }

    get conferences(): ConferenceEventInfo[] {
        const data = this.fetchAllConferencesPromise?.value;
        if (
            Array.isArray(data) &&
            data.find((value) => !isConferenceEventInfo(value))
        ) {
            return data;
        }

        return [];
    }

    findConferenceInfo = computedFn((conferenceId?: string) => {
        if (!conferenceId) return;
        const promise = this.loadingCallInfo.get(conferenceId);
        if (promise?.state === 'fulfilled') {
            return promise.value;
        }
    });

    isConferenceInfoLoading = computedFn((conferenceId?: string) => {
        if (!conferenceId) return false;
        const promise = this.loadingCallInfo.get(conferenceId);
        return promise?.state === 'pending';
    });

    findStudio = computedFn(
        (studioName: string, machineName: string) => {
            return this.studios.find(
                (studio) =>
                    studio.studioName === studioName &&
                    studio.machineName === machineName
            );
        },
        { keepAlive: true }
    );

    findConferenceInfoById = computedFn(
        (conferenceId?: string): ConferenceEventInfo => {
            if (!conferenceId) {
                return CONFERENCE_INFO_STUB;
            }
            return (
                this.conferences.find(
                    (value) => value.conferenceId === conferenceId
                ) ?? CONFERENCE_INFO_STUB
            );
        },
        { keepAlive: true, name: ' find conference info by id' }
    );
}
