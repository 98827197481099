export function getOrInitTabSession(): string | undefined {
    if (
        typeof window !== 'undefined' &&
        'sessionStorage' in window &&
        !sessionStorage.id
    ) {
        sessionStorage.id = (Math.random() * 100_000_000).toString();
        return sessionStorage.id;
    }

    return undefined;
}
